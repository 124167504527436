import React from "react";
import clsx from "clsx";

import { Carousel } from "@molecules";
import { Container, Video } from "@atoms";
import RibbonsThree from "@svg/RibbonsThree";

const VideoCarousel = ({ videos, className: _className }) => {
  return (
    <section className={clsx(_className, "relative")}>
      <Container variant="xs" className="relative z-10">
        <div className="px-4 md:px-8">
          <Carousel maxVisible={1} narrow>
            {videos?.map(({ videoUrl, caption }) => (
              <div key={videoUrl}>
                {/* todo: pause inactive videos */}
                <Video url={videoUrl} />
                <div className="mt-4 text-xs">{caption}</div>
              </div>
            ))}
          </Carousel>
        </div>
      </Container>
      <div className="absolute inset-0 z-0 flex items-center justify-center">
        <RibbonsThree className="w-full translate-y-1/2" />
      </div>
    </section>
  );
};

export default VideoCarousel;
